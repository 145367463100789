

export const endpoints = {
    login: "auth/login",
    signup: "auth/register",
    send_otp: "otp/send",
    verify_otp: "otp/verify",
    create_pin: "account/pin",
    sendPasswordRetrievalCode: "auth/password/retrieval",
    updatePassword: "auth/password/reset",
    sendPINRetrievalCode: "account/pin/retrieval",
    updatePIN: "account/pin/change",
    fetchReferrals: "user/referrals",
    getUser: "user",
    payBill: "bill",
    fetchWalletHistories: "wallet/histories",
    fetchPlans: "bill/plans",
    fetchProviders: "bill/providers",
    fetchBanks: "banks",
    fetchProducts: "bill/products",
    resolveCustomer: "bill/customer",
    fetchTransactions: "transactions",
    createInvoice: "payment/invoice",
    createInvoiceSDK: "paymentsdk/invoice",
    sendCard: "payment/card",
    verifyPaymentOTP: "payment/confirm",
    requestWithdrawal: "account/withdraw",
    fundWallet: "paymentsdk/funding",
    requestFundingAccount: "user/request-funding-account",
    initiateWalletPayment: 'payment/initiate'
}
