import React, { lazy, useEffect } from 'react';
import { lazily } from 'react-lazily';
import { useDispatch, useSelector } from 'react-redux';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import { useHistory } from 'react-router';
import { DgModal } from '../ui-component/dg-modal';
//import { Airtime, AirtimeSelect, Internet, CableTV, CableTVSelect, Power, Examinations } from '../views/bills';

import MainLayout from '../layout/MainLayout';
import { ACCOUNT_REFRESH } from '../store/actions';
import TransactionCompleted from '../ui-component/transactions/completed';

import VerifyPaymentOTP from '../views/account/funding/VerifyPaymentOTP';
import Vouchers from '../views/bills/USSDPrinting/Vouchers';
import {RoutePaths} from './RoutePaths';
import { CreatePin } from '../ui-component/create-pin';
import { RequestFundingAccount } from '../ui-component/add-bank-details';

const DashboardDefault = lazy(() => import('../views/dashboard/Default'));
const Transactions = lazy(() => import('../views/transactions'));
const Referrals = lazy(() => import('../views/referrals'));

const { USSDPrinting, SendMoney, Airtime, AirtimeSelect, Internet, CableTV, CableTVSelect, Power, Examinations, ExaminationSelect } = lazily(
    () => import("../views/bills")
);

const { Withdrawal, Funding, WalletHistory, AccountUpgrade, Account, AccountPassword, AccountPIN } = lazily(
    () => import("../views/account")
);

const { TransactionConfirmation, TransactionWithCardConfirmation, TransactionSummary } = lazily(
    () => import("../views/confirmations")
);

const ProtectedRoutes = ({component: Component, ...rest}) => {
    const location = useLocation();
    const account = useSelector(state => state.account);
    const history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        history.listen((location, action) => {
            if (location.pathname !==  RoutePaths.dashboard) {
                dispatch({type: ACCOUNT_REFRESH, payload: {refresh: true}});
            }
        });
    }, [dispatch, history]);

    return (
        <Route
            {...rest}
            path={[
                RoutePaths.dashboard,
                RoutePaths.transactions,

                RoutePaths.referrals,

                RoutePaths.airtime,
                RoutePaths.airtime_select,
                
                RoutePaths.internet,
                RoutePaths.vouchers,
                RoutePaths.vouchers_print,
                RoutePaths.send_money,

                RoutePaths.cabletv,
                RoutePaths.cabletv_select,

                RoutePaths.power,
                RoutePaths.examination,
                RoutePaths.examination_select,
                
                RoutePaths.withdraw,
                RoutePaths.deposit,
                RoutePaths.verifyPaymentOTP,

                RoutePaths.account,
                RoutePaths.wallet_history,
                RoutePaths.account_upgrade,
                RoutePaths.account_password,
                RoutePaths.account_pin,

                RoutePaths.txn_confirmation,
                RoutePaths.txn_card_confirmation,
                RoutePaths.txn_summary,
                RoutePaths.txn_completed
            ]}

            render={
                props => {
                    if (account?.token) {
                        return(
                            <MainLayout showBreadcrumb={true}>
                                <DgModal visible={account?.user && (!account?.user?.verification?.pin_created)}>
                                    {!account?.user?.verification?.pin_created && <CreatePin />}
                                </DgModal>

                                <Switch location={location} key={location.pathname}>
                                    <Route path={RoutePaths.dashboard} exact component={DashboardDefault} />
                                    <Route path={`${RoutePaths.transactions}/:id?`} exact component={Transactions} />
                                    <Route path={RoutePaths.referrals} exact component={Referrals} />
                                    <Route path={RoutePaths.airtime} exact component={Airtime} />
                                    <Route path={RoutePaths.airtime_select} exact component={AirtimeSelect} />
                                    <Route path={RoutePaths.internet} exact component={Internet} />
                                    <Route path={RoutePaths.vouchers} exact component={USSDPrinting} />
                                    <Route path={RoutePaths.vouchers_print} exact component={Vouchers} />
                                    <Route path={RoutePaths.send_money} exact component={SendMoney} />
                                    <Route path={RoutePaths.cabletv} exact component={CableTV} />
                                    <Route path={RoutePaths.cabletv_select} exact component={CableTVSelect} />
                                    <Route path={RoutePaths.power} exact component={Power} />
                                    <Route path={RoutePaths.examination} exact component={Examinations} />
                                    <Route path={RoutePaths.examination_select} exact component={ExaminationSelect} />
                                    <Route path={RoutePaths.withdraw} exact component={Withdrawal} />
                                    <Route path={RoutePaths.deposit} exact component={Funding} />
                                    <Route path={RoutePaths.verifyPaymentOTP} exact component={VerifyPaymentOTP} />
                                    <Route path={RoutePaths.account} exact component={Account} />
                                    <Route path={RoutePaths.wallet_history} exact component={WalletHistory} />
                                    <Route path={RoutePaths.account_upgrade} exact component={AccountUpgrade} />
                                    <Route path={RoutePaths.account_password} exact component={AccountPassword} />
                                    <Route path={RoutePaths.account_pin} exact component={AccountPIN} />
                                    <Route path={RoutePaths.txn_confirmation} exact component={TransactionConfirmation} />
                                    <Route path={RoutePaths.txn_card_confirmation} exact component={TransactionWithCardConfirmation} />
                                    <Route path={RoutePaths.txn_summary} exact component={TransactionSummary} />
                                    <Route path={RoutePaths.txn_completed} exact component={TransactionCompleted} />
                                </Switch>
                            </MainLayout>
                        );
                    }
                    else {
                        return <Redirect exact to={{
                            pathname: RoutePaths.login,
                            state: {
                                from: props.location
                            }
                        }} />;
                    }
                }
            }
        />
    );
};

export default ProtectedRoutes;
