import React, { useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
//import {Link as RouterLink} from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    makeStyles,
    OutlinedInput
} from '@material-ui/core';

import ClipLoader from "react-spinners/ClipLoader";

import { rgba, theme as customTheme } from '../../constants';
import { useDispatch } from 'react-redux';
import { endpoints } from '../../api/endpoints';
import { useHistory } from 'react-router';
import { postData } from '../../api/httpClient';
import { dispatchError } from '../../shared/functions';
import hotToast from '../../utils/hotToast';

import { ACCOUNT_REFRESH } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    redButton: {
        fontSize: '1rem',
        fontWeight: 500,
        backgroundColor: theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
        }
    },
    margin: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    title: {
        color: theme.palette.grey[600]
    },
    bill: {
        backgroundColor: customTheme.darkBlue,
        borderRadius: "8px",
        '&:hover': {
            backgroundColor: rgba(34, 51, 68, 0.9)
        }
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: "220px",
        '& > label': {
            top: '23px',
            left: 0,
            color: theme.palette.grey[500],
            '&[data-shrink="false"]': {
                top: '5px'
            }
        },
        '& > div > input': {
            padding: '30.5px 14px 11.5px !important'
        },
        '& legend': {
            display: 'none'
        },
        '& fieldset': {
            top: 0
        }
    },
    formSelect: {
        "& > div": {
            paddingTop: "1rem"
        }
    },
    startAdornment: {
        color: theme.palette.grey[500],
        marginTop: '18px',
        width: 'auto'
    },
    link: {
        color: customTheme.skyBlue,
        textDecoration: "none"
    },
    error: {
        color: customTheme.textDanger
    }
}));

const BillForm = (props, { className, ...rest }) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const dispatch = useDispatch();
    const [accountName, setAccountName] = useState(null);
    const history = useHistory();

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    bvn: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    bvn: Yup.string().max(45).required('BVN is required'),
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    setLoading(true);

                    const data = {
                        bank: values.bank_code,
                        account_number: values.account_number,
                        account_name: accountName,
                        bvn: values.bvn
                    }

                    await postData(endpoints.requestFundingAccount, data, (res) => {//Success
                        setLoading(false);
                        hotToast.success(res?.message);
                        dispatch({ type: ACCOUNT_REFRESH, payload: { refresh: true } });
                    }, (res) => { //Error
                        setLoading(false);
                        dispatchError(res, dispatch, history);
                    });
                }}
            >
                {({ errors, handleBlur, handleSubmit, handleChange, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
                        <h1 style={{ alignSelf: "center" }}>Create Virtual Account</h1>
                        <p style={{ color: 'grey' }}>
                            Please provide a valid BVN as mandated by CBN.
                        </p>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.bvn && errors.bvn)}
                            className={classes.formControl}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="bvn">Enter your BVN</InputLabel>
                            <OutlinedInput
                                id="bvn"
                                type="text"
                                value={values.bvn}
                                name="bvn"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label='Bank Verification Number'

                            />
                            {touched.bvn && errors.bvn && (
                                <FormHelperText error id="bvn">
                                    {' '}
                                    {errors.bvn}{' '}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box mt={4} mb={2}>
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className={classes.bill}
                            >
                                {!loading && 'Request Virtual Account'} <ClipLoader color={"#FFFFFF"} loading={loading} css={classes.loader} size={30} />

                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default BillForm;
