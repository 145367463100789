import { Modal } from "antd";
import React from "react";

const DgModal = ({ visible, setVisible, children, title, closable = false }) => {
    return (
        <Modal
            title={title}
            visible={visible}
            footer={null}
            closable={closable}
            onCancel={() => {
                if (closable && typeof setVisible === 'function') {
                    setVisible(false);
                }
            }}
        >
            {children}
        </Modal>
    );
};

export default DgModal;
